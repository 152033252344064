import React from "react";
// import qubelogo from "../../images/Home/hqlogo-with-tag.png"
import { FaLongArrowAltRight } from "react-icons/fa"

function ShowCaseHeader() {

  return (
    <>
      <section className="new-showcase text-light p-5 text-center text-sm-start" id="Hero" >
        <div className="container-bg container h-100 d-flex flex-column align-content-center justify-content-center">
          <div className="d-flex flex-column align-content-center justify-content-between container-content">
            {/* <div className="comp-logo-display d-flex align-content-center justify-content-center">
              <img src={qubelogo} alt="QUBE" />
            </div> */}
            <div className="hero-banner-container">
              <div>
                <h1 className="intro mt-3">
                  {/* A pandora's box of decentralized tools and products for humanity's regenerative habitation */}
                  Disrupting<br />Worldwide<br />Home<br />Scarcity<br />
                </h1>
                <div className="new-hero-tag-container mb-1">
                  <p className="new-hero-tag">
                    {/* Let’s Build/Live like Ants—not Monkeys. */}
                    {/* Decentralized Technologies for Home Building + Shelter Needs */}
                    {/* Marketplace for Modular Home Components + Rooms */}
                    {/* A Web3 Framework for Home Development */}
                    {/* A Web3 Framework for Home Development System */}
                    {/* A Modular Built Environment, on Web3 */}
                    {/* A Modular Ecosystem for the Built Environment, on Web3. */}
                    {/* A Modular Built Environment Engine. */}
                    {/* A Modular Housing Platform */}
                    Accelerate the World into Renewable and Regenerative Housing
                  </p>
                  {/* <p className="new-hero-tag">
                    Deconstructing Systems.
                  </p>
                  <p className="new-hero-tag">
                    Decentralizing Power.
                  </p>
                  <p className="new-hero-tag">
                    Democratizing Knowledge.
                  </p> */}
                </div>
              </div>
              <div className="intro-btn2">
                <a href="https://shop.homeqube.com/login" target="_blank" rel="noopener noreferrer">
                  <button className="button6 btn-lg rounded-pil">
                    Explore our Solutions  &nbsp; <FaLongArrowAltRight />
                  </button>
                </a>
              </div>
            </div>
            {/* <div className="yt-vid">
              <video width="100%" height="100%" autoPlay loop muted>
                <source
                  src="https://s3.amazonaws.com/www.qube.homeqube.com/video/Homeqube-ICO.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://s3.amazonaws.com/www.qube.homeqube.com/video/Homeqube-ICO.ogg"
                  type="video/ogg"
                />
              </video>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default ShowCaseHeader;
